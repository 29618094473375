import { useEffect, useRef } from 'react';

import styles from '@/pages/Landing.module.scss';

const LANDING_URL = 'https://timepercentcorp.com/tradingbank';

const Landing = () => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    Nachocode.refresh.setPullToRefresh(false);

    return () => {
      Nachocode.refresh.setPullToRefresh(true);
    };
  }, []);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      const handleTouchMove = (event: Event) => {
        event.stopPropagation();
      };

      iframe.contentWindow.addEventListener('touchmove', handleTouchMove, {
        passive: false,
      });

      return () => {
        iframe.contentWindow?.removeEventListener('touchmove', handleTouchMove);
      };
    }
  }, []);

  return (
    <main>
      <iframe ref={iframeRef} src={LANDING_URL} className={styles.blog} />
    </main>
  );
};

export default Landing;
